import { Carousel } from "antd"
import React from "react";
import { useTranslation } from "react-i18next";

type CarouselSectionProps = {
    OnSelectMenuItem: (item: string) => void;
};

export const CarouselSection = React.forwardRef<HTMLDivElement, CarouselSectionProps>((props, ref) => {
    const { t } = useTranslation();
    const { OnSelectMenuItem } = props;

    return <Carousel
        className="carousel_top"
        arrows={true}
        autoplay={true}
        autoplaySpeed={4000}
        infinite={true}>
        <div className="section_container section_1 section_middle">
            <div className="container">
                <div className="">
                    <h1>{t('Carousel.CarouselItem_1.Title')}</h1>
                    <h2>{t('Carousel.CarouselItem_1.SubTitle')}</h2>
                    <p>{t('Carousel.CarouselItem_1.Text')}</p>
                    <p>{t('Carousel.CarouselItem_1.SubText')}</p>
                    <div className="btn_wrapper">
                        <div onClick={() => OnSelectMenuItem('AboutUs')} className="btn">{t('Carousel.CarouselBtn')}</div>
                    </div>
                </div>
            </div>
        </div>
        <div className="section_container section_1 section_middle">
            <div className="container">
                <div className="">
                    <h1>{t('Carousel.CarouselItem_2.Title')}</h1>
                    <h2>{t('Carousel.CarouselItem_2.SubTitle')}</h2>
                    <p>{t('Carousel.CarouselItem_2.Text')}</p>
                    <p>{t('Carousel.CarouselItem_2.SubText')}</p>
                    <div className="btn_wrapper">
                        <div onClick={() => OnSelectMenuItem('AboutUs')} className="btn">{t('Carousel.CarouselBtn')}</div>
                    </div>
                </div>
            </div>
        </div>
        <div className="section_container section_1 section_middle">
            <div className="container">
                <div className="">
                    <h1>{t('Carousel.CarouselItem_3.Title')}</h1>
                    <h2>{t('Carousel.CarouselItem_3.SubTitle')}</h2>
                    <p>{t('Carousel.CarouselItem_3.Text')}</p>
                    <p>{t('Carousel.CarouselItem_3.SubText')}</p>
                    <div className="btn_wrapper">
                        <div onClick={() => OnSelectMenuItem('AboutUs')} className="btn">{t('Carousel.CarouselBtn')}</div>
                    </div>
                </div>
            </div>
        </div>
    </Carousel>
})