import { useTranslation } from "react-i18next";

interface IExpertiseData {
    iconUrl: string;
    label: string;
    link: string;
}

export const ExpertiseSection = () => {
    const { t } = useTranslation();

    const expertiseData: Array<IExpertiseData> = [
        {
            iconUrl: '/icons/e-commerce_icon.svg',
            label: t('Expertise.Label_1'),
            link: '/'
        },
        {
            iconUrl: '/icons/healthcare_icon.svg',
            label: t('Expertise.Label_2'),
            link: '/'
        },
        {
            iconUrl: '/icons/fintech_icon.svg',
            label: t('Expertise.Label_3'),
            link: '/'
        },
        {
            iconUrl: '/icons/IoT_icon.svg',
            label: t('Expertise.Label_4'),
            link: '/'
        },
        {
            iconUrl: '/icons/telrcommunication_icon.svg',
            label: t('Expertise.Label_5'),
            link: '/'
        },
        {
            iconUrl: '',
            label: '',
            link: '/'
        },
        {
            iconUrl: '/icons/e-learning_icon.svg',
            label: t('Expertise.Label_6'),
            link: '/'
        },
        {
            iconUrl: '/icons/logistics_icon.svg',
            label: t('Expertise.Label_7'),
            link: '/'
        },
        {
            iconUrl: '/icons/traveling_icon.svg',
            label: t('Expertise.Label_8'),
            link: '/'
        }
    ]

    return <div className="section_container section_expertise section_middle">
        <div className="container">
            <div className="section_title">
                <span>{t('Expertise.SectionTitle')}</span>.
            </div>
            <div className="text_group">
                {expertiseData.map((item: IExpertiseData, index) => {
                    return <div className="group_item" key={index}>
                        <div className="item_icon">
                            <img alt={item.label} src={item.iconUrl} />
                        </div>
                        <div className="item_label">{item.label}</div>
                    </div>
                })}
            </div>
        </div>
    </div>
}