import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export const TechnologiesSection = () => {
    const techLogos = [
        { name: '1', logo: '/tech/9.png' },
        { name: '2', logo: '/tech/8.png' },
        { name: '3', logo: '/tech/1.png' },
        { name: '4', logo: '/tech/2.png' },
        { name: '5', logo: '/tech/3.png' },
        { name: '6', logo: '/tech/4.png' },
        { name: '7', logo: '/tech/5.png' },
        { name: '8', logo: '/tech/6.png' },
        { name: '9', logo: '/tech/7.png' },
    ];

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 6,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 800,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 420,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            }
        ]
    }

    return <div className="section_container section_technologies">
        <div className="container">
            <Slider {...settings}>
                {techLogos.map((tech, index) => (
                    <div key={index}>
                        <img src={tech.logo} alt={tech.name} />
                    </div>
                ))}
            </Slider>
        </div>
    </div>
}