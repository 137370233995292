import { useTranslation } from "react-i18next";

export const Menu = (props: { OnSelectMenuItem: (item: string) => void }) => {
    const { t } = useTranslation();

    return <>
        <li>
            <div onClick={() => props.OnSelectMenuItem('Home')}>{t('HeaderMenu.Home')}</div>
        </li>
        <li>
            <div onClick={() => props.OnSelectMenuItem('Services')}>{t('HeaderMenu.Services')}</div>
        </li>
        <li>
            <div onClick={() => props.OnSelectMenuItem('OurValues')}>{t('HeaderMenu.OurValues')}</div>
        </li>
        <li>
            <div onClick={() => props.OnSelectMenuItem('AboutUs')}>{t('HeaderMenu.AboutUs')}</div>
        </li>
    </>
}