import React from 'react';
import ReactDOM from 'react-dom/client';
import './assets/scss/main.scss';
import { MasterPage } from './components/master.page';
import { BrowserRouter } from 'react-router-dom';

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    <React.StrictMode>
        <BrowserRouter>
            <MasterPage />
        </BrowserRouter>
    </React.StrictMode>
);