/* eslint-disable react-hooks/exhaustive-deps */
import { useLocation, useNavigate, useSearchParams } from "react-router-dom"
import { CarouselSection } from "../sections/carousel"
import { ExpertiseSection } from "../sections/expertise"
import { ServicesSection } from "../sections/services"
import { TechnologiesSection } from "../sections/technologies"
import { ValuesSerction } from "../sections/values"
import { AboutUs } from "./about.us.page"
import AOS from 'aos';
import { useEffect } from "react"
import { useTranslation } from "react-i18next"

interface IProps {
    ServicesSectionRef: any;
    ValuesSerctionRef: any;
    AboutUsRef: any;
    OnSelectMenuItem: (item: string) => void;
}

export const HomePage = (props: IProps) => {
    const location = useLocation();
    const { i18n } = useTranslation();
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();

    useEffect(() => {
        const { language } = i18n;
        if (language && location && searchParams.size === 0) {
            if (language === 'lt') {
                navigate(`${location.pathname}?lng=${language}`, { replace: true })
            } else {
                navigate(`${location.pathname}`, { replace: true })
            }
        }
    }, [])

    useEffect(() => {
        AOS.init({
            disable: () => window.innerWidth < 1300,
            startEvent: 'DOMContentLoaded',
            initClassName: 'aos-init',
            animatedClassName: 'aos-animate',
            useClassNames: false,
            disableMutationObserver: true,
            debounceDelay: 50,
            throttleDelay: 99,
            offset: 0,
            delay: 0,
            duration: 400,
            easing: 'ease',
            once: false,
            mirror: true,
            anchorPlacement: 'top-bottom',
        });
    }, [])

    useEffect(() => {
        AOS.refresh();
    }, [location])


    return <div className="masterPage_content">
        <CarouselSection OnSelectMenuItem={props.OnSelectMenuItem} />
        <TechnologiesSection />
        <ServicesSection ref={props.ServicesSectionRef} />
        <ExpertiseSection />
        <ValuesSerction ref={props.ValuesSerctionRef} />
        <AboutUs ref={props.AboutUsRef} />
    </div>
}