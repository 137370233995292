import React from "react";
import { useTranslation } from "react-i18next";

interface IValuesData {
    iconUrl: string;
    title: string;
    text: string;
}

export const ValuesSerction = React.forwardRef<HTMLDivElement>((props, ref) => {
    const { t } = useTranslation();

    const valuesData: Array<IValuesData> = [
        {
            iconUrl: '/icons/unified_team_icon.svg',
            title: t('OurValues.GroupItem_1.Title'),
            text: t('OurValues.GroupItem_1.Text'),
        },
        {
            iconUrl: '/icons/innovation_icon.svg',
            title: t('OurValues.GroupItem_2.Title'),
            text: t('OurValues.GroupItem_2.Text'),
        },
        {
            iconUrl: '/icons/craft_icon.svg',
            title: t('OurValues.GroupItem_3.Title'),
            text: t('OurValues.GroupItem_3.Text'),
        },
        {
            iconUrl: '/icons/synergy_icon.svg',
            title: t('OurValues.GroupItem_4.Title'),
            text: t('OurValues.GroupItem_4.Text'),
        },
        {
            iconUrl: '/icons/bestinclass_icon.svg',
            title: t('OurValues.GroupItem_5.Title'),
            text: t('OurValues.GroupItem_5.Text'),
        },
        {
            iconUrl: '/icons/inclusive_culture_icon.svg',
            title: t('OurValues.GroupItem_6.Title'),
            text: t('OurValues.GroupItem_6.Text'),
        },
    ]

    return <div className="section_container section_values section_middle" ref={ref}>
        <div className="container">
            <div className="section_title">
                <span>{t('OurValues.SectionTitle')}</span>.
            </div>
            <p>{t('OurValues.SectionTitle_text')}</p>
            <div className="text_group">
                {valuesData.map((item: IValuesData, index) => {
                    return <div className="group_item"
                        data-aos="zoom-out"
			data-aos-easing="ease-in"
                        data-aos-anchor-placement="center-center"
			data-aos-duration="3000"
                        data-aos-delay={`${(index + 1) * 40}0`}
                        key={index}>
                        <div className="item_icon">
                            <img src={item.iconUrl} alt={item.title} />
                        </div>
                        <div className="text_col">
                            <div className="item_title">{item.title}</div>
                            <div className="item_text">{item.text}</div>
                        </div>
                    </div>
                })}
            </div>
        </div>
    </div>
});