/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation, useNavigate, useSearchParams } from "react-router-dom"

export const PrivacyPolicy = () => {
    const PrivacyPolicyRef = useRef<HTMLDivElement>(null);
    const location = useLocation();
    const { i18n } = useTranslation();
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();

    const { t } = useTranslation();

    useEffect(() => {
        const { language } = i18n;
        if (language && location && searchParams.size === 0) {
            
            if (language === 'lt') {
                navigate(`${location.pathname}?lng=${language}`, { replace: true })
            } else {
                navigate(`${location.pathname}`, { replace: true })
            }
        }
    }, [])


    useEffect(() => {
        if (PrivacyPolicyRef && PrivacyPolicyRef.current) {
            PrivacyPolicyRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [])

    return <div className="privacyPolicy_page" ref={PrivacyPolicyRef}>
        <div className="container">
            <div className="section bg-padding">
                <h3>PRIVACY POLICY</h3>
                <small>Last updated August 2, 2024</small>
                <hr />
                <h2>INTRODUCTION</h2>
                <p>
                    <b>Residium MB</b> (hereinafter "we," "us," or "our") is committed to protecting the privacy of our users ("user").
		    This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you visit our website www.residium.net.
                    If you disagree with this policy's terms, please refrain from accessing our website.
                </p>
                <h2>DATA COLLECTION</h2>
                <p>We may collect information about you in a variety of ways. The information we may collect on the Site includes:</p>
                <h4><b>Personal Information</b></h4>
                <p>
                    You may voluntarily provide us with personally identifiable information, such as your name, email address, phone number, and demographic details like gender and location. You are not obligated to provide us with any personal information; however, your refusal to do so may limit your access to certain features of our website.
                </p>
                <h4><b>Usage Data</b></h4>
                <p>
		    Our servers automatically collect information when you access our website, such as your IP address, browser type, access times, and the pages you viewed immediately before and after visiting our website.
                </p>
                <h4><b>Web Cookies</b></h4>
                <p>
		    We utilize website cookies - small pieces of data sent to your browser from our web server and stored on your hard drive for record-keeping purposes and sometimes to track analytics information. Users have the option to configure their web browsers to refuse cookies or to be alerted when cookies are being sent. Please note that if you do so, some parts of our website may not function properly, and certain features may not work as intended.
                </p>

                <h2>INFORMATION USAGE</h2>
                <p className="sm-m">
		    We use the information we collect about you to provide you with a seamless, efficient, and personalized experience on our website. Specifically, we may use your information to:
                </p>
                <ul>
                    <li><p className="sm-m1"><b>Deliver services:</b> We may use your information to fulfill your requests and provide you with the services you have requested.</p></li>
                    <li><p className="sm-m1"><b>Communicate with you:</b> We may use your information to send you emails related to your requests, updates about our services, or other relevant communications.</p></li>
                    <li><p className="sm-m1"><b>Enable user interactions:</b> We may use your information to facilitate communication between users on our platform.</p></li>
                    <li><p className="sm-m1"><b>Personalize your experience:</b> We may use your information to create a personalized profile for you, enhancing your future visits to our website.</p></li>
                    <li><p className="sm-m1"><b>Improve website performance:</b> We may use your information to analyze website usage and trends, optimize performance, and improve your overall experience.</p></li>
                    <li><p className="sm-m1"><b>Conduct business operations:</b> We may use your information to carry out other business activities, such as marketing and sales, as needed.</p></li>
                    <li><p className="sm-m1"><b>Resolve issues and provide support:</b> We may use your information to resolve disputes, troubleshoot problems, and respond to product and customer service inquiries.</p></li>
                    <li><p className="sm-m1"><b>Seek website support:</b> We may use your information to solicit support for our website and its development.</p></li>
                </ul>
                <h2>INFORMATION DISCLOSURE</h2>
                <p className="sm-m">
                    We may disclose your information if we believe it is necessary to comply with legal process, investigate potential policy violations, or protect the rights, property, or safety of others. This may include sharing information with other entities for fraud prevention and credit risk reduction.
                </p>
                <p>
		    You have the right to access, amend, delete, or migrate your personal information stored by us. You can also restrict the processing of your personal information. To exercise these rights, please send your request to <Link to={`mailto:${t('Footer.Email')}`} className="gray-href">{t('Footer.Email')}</Link>.
                </p>

                <h2>TRACKING</h2>
                <h4><b>Cookies</b></h4>
                <p>
                    We may use cookies on our website to personalize your experience and enhance website performance. These cookies do not collect your personal information directly. You can disable or delete cookies through your browser settings, which may affect your access to certain features or functionality on our website.
                </p>
                <h4><b>Website Analytics</b></h4>
                <p>
		    We may collaborate with third-party vendors like Google Analytics or Cloudflare to analyze website usage, track user activity, and understand the popularity of specific content. These vendors may use both first-party and third-party cookies to provide their services.
                </p>
                <h4><b>Opt-Out Options</b></h4>
                <p>
		    You can adjust your browser settings to decline all cookies or to receive notifications whenever a cookie is being used, allowing you to decide on a case-by-case basis. However, please note that clearing your browser cookies, upgrading your browser, or using a new computer may reset any opt-out settings you have made.
                </p>
                <h2>INFORMATION SECURITY</h2>
                <p>
                    We implement administrative, technical, and physical security measures to protect your personal information. While we strive to ensure the security of your data, no security system is foolproof, and no method of data transmission can guarantee complete protection from interception or misuse. Remember that any information shared online is vulnerable to unauthorized access. Therefore, we cannot guarantee the absolute security of your personal information if you choose to provide it to us.
                </p>
                <p><strong>If you wish to opt out of receiving correspondence, emails, or other communications from us, please contact us at <Link to={`mailto:${t('Footer.Email')}`} className="gray-href">{t('Footer.Email')}</Link>.</strong></p>
            </div>

        </div>
    </div>
}